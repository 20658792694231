<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <ProjectInfo />
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <ChartError
              v-if="!!changes"
              :data="changes.description.chartData"
              :description="changes.description"
              :filterType="'none'"
              :chartType="'microorganisms'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/Microorganisms/BodyHeader.vue";
import ChartError from "@/components/Generic/ChartError.vue";
import { useProjectsStore } from "@/store/projects";
//import axios from "axios";


export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      i: 1,
      changes: null,
      selectedType: null,
      loading: true,
      displayModal: false,
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {
    this.changes = {
    "description": {
        "organism": "Organismos de ocorrência única",
        "chartData": {
            "Organismos de ocorrência única": {
                "control": [
                    {
                        "type": "Azospirillum brasiliense",
                        "avgH": 0.01,
                        "avgT": 5.2,
                        "avgLowT ": "",
                        "avgHighT ": ""
                    },
                    {
                        "type": "Rhizobium rhizoryzae",
                        "avgH": 2.5,
                        "avgT": 0.5,
                        "avgLowT ": "",
                        "avgHighT ": ""
                    },
                    {
                        "type": "Flavobacterium",
                        "avgH": 0.5,
                        "avgT": 1.5,
                        "avgLowT ": "",
                        "avgHighT ": ""
                    }
                ],
                "treatment": [
                    {
                        "type": "Xanthomonas",
                        "avgH": 2,
                        "avgT": 0.01,
                        "avgLowT ": "",
                        "avgHighT ": ""
                    }
                ]
            }
        }
    }
};
    /*axios
      .get(
        "https://dev.b4adashboard.com/api/b4proof/resultados/singleOccurringOrganisms/94/1/0"
      )
      .then((response) => {
        this.changes = response.data;
      })
      .catch((error) => {
        console.error(error);
      });*/
  },
  beforeUnmount() {},
  created() {},
  methods: {},
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    ChartError: ChartError,
  },
};
</script>

<style></style>
